import Vue from 'vue'
import {ToastPlugin, ModalPlugin} from 'bootstrap-vue'
import '@/libs/acl'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins

import '@/libs/portal-vue'
import AsyncComputedPlugin from 'vue-async-computed'
import 'devextreme/dist/css/dx.light.css';
import VueSimpleAlert from "vue-simple-alert";
import {MsalPlugin, msalPluginInstance, MsalPluginOptions} from "@/auth/msal-plugin";
import VueToastPlugin from 'vue-toast-notification';
// Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
// devExtreme
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "ace-builds/css/ace.css";
import "ace-builds/css/theme/dreamweaver.css";
import "ace-builds/css/theme/ambiance.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import axios from "@/libs/axios";

Vue.use(VueSimpleAlert);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueToastPlugin,{
    // One of the options
    position: 'top-right'
})

// Composition API

Vue.use(AsyncComputedPlugin)

// Microsoft Auth
const options: MsalPluginOptions = {
    clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
    loginAuthority: process.env.VUE_APP_MSAL_LOGIN_AUTHORITY,
    passwordAuthority: process.env.VUE_APP_MSAL_PASSWORD_RESET_AUTHORITY,
    knownAuthority: process.env.VUE_APP_MSAL_KNOWN_AUTHORITY,
    redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI,
} as any;

console.log(options);
Vue.use(new MsalPlugin(), options);

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')

const oldFetch = window.fetch
window.fetch = async (input, options) => {
    console.log('fetching', input, options)
    if (input.includes(axios.defaults.baseURL)) {
        const token = await msalPluginInstance.acquireToken();
        options.headers = {
            Authorization: `Bearer ${token}`
        }
    }
    return await oldFetch(input, options)
}
