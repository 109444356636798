import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import User from '@/store/modules/user'
import LoadingModule from '@/store/modules/loading'
import reloadTabs from "@/store/modules/reloadTabs";
import formLoading from "@/store/modules/formLoading";

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        app,
        appConfig,
        verticalMenu,
        User,
        loading: LoadingModule,
        reloadTabs,
        formLoading,
    },
    strict: process.env.DEV,
})
